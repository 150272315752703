body {
    background-color: #f5f5f5;
}

a.custom-card,
a.custom-card:hover {
    color: inherit;
    text-decoration: none !important;
}

a:hover {
    color: #1e4c92;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    background-color: #1e4c92;
}